<template>
    <div class="h-100">
        <template>
            <v-row class="h-100" v-if="selected_till && selected_till.status=='abierta'">
                <v-col cols="3" class="section-list-stations pr-0">
                    <stations-list></stations-list>
                </v-col>
                <v-col cols="9" class="section-stations-movements" >
                    <v-container v-if="selected_station">
                        <v-row class="h-100">
                            <v-col cols="7" class="pt-1 pb-0">
                                <Catalogue></Catalogue>
                            </v-col>
                            <v-col cols="5" class="pt-1 pb-0">
                                <Cart></Cart>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>                
            </v-row>
            <v-row v-else>
                <v-col cols="12">
                    <closed-till></closed-till>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import StationsList from '../../../components/stations/StationsList';
import Cart from '../../../components/sales/Cart';
import Catalogue from '../../../components/sales/Catalogue';
import ClosedTill from '../../../components/tills/ClosedTill';

export default {
    name: 'Stations',
    components: {
        StationsList,
        Cart,
        Catalogue,
        ClosedTill
    },
    computed: {
        ...mapState('stations', [
            'selected_station'
        ]),
        ...mapState('tills', [
            'tills',
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('sales', [
            'setDateInvoice'
        ]),
        ...mapActions('stations', [
            'selectStation'
        ]),
        ...mapActions('tills', [
            'getAvailable',
            'select_till'
        ]),
        ...mapActions('accounts', [
            'getAccounts',
        ]),
        async getCurrentUserTill(){
            let filters = {
                till_id: this.till_user_id
            }
            await this.getAvailable(filters);
            if (this.tills.length > 0) {
                this.select_till(this.tills[0]);
            }
        }
    },
    created() {
        this.getCurrentUserTill();
        this.getAccounts();
    },
    destroyed () {
        this.selectStation(null);
        this.setDateInvoice(this.$moment().format('YYYY-MM-DD'));
    }
}
</script>

<style scoped>
.section-list-stations{
    height: 100% !important;
    background-color: #f4f4f6 !important;
}

.section-stations-movements{
    height: 100% !important;
    background-color: #ffffff !important;
}
</style>