<template>
    <v-container class="pt-2">
        <v-row>
            <v-col cols="12" class="pt-0">
                <span class="subtitle-1 font-weight-medium">Estaciones de servicio en {{ office_login_name | capitalize }}</span>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-btn color="green" dark @click="handleViewCreateStation">
                    Crear
                </v-btn>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" class="pt-0 pb-1">
                <v-container class="pt-0">
                    <v-row v-for="station in stations" :key="station.id"
                        justify="center"
                        :class="'row-station ' + getClassStationActive(station)"
                    >
                        <v-col cols="11"
                            @click="handleSelectStation(station)"
                            class="station-item"
                        >
                            <template>
                                <v-row>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <v-icon :class="'mr-2 ' + station.status" size="17">
                                            fas fa-person-booth
                                        </v-icon>
                                        <span class="station-name">{{ station.name }}</span>
                                    </v-col>
                                    <v-col cols="12" class="pt-1 pb-1">
                                        <span :class="'label-station-status status-' + station.status">{{ station.status | uppercase }}</span>
                                        <v-icon color="red" 
                                            class="float-right" 
                                            size="19"
                                            @click="handleDeleteStation(station)">
                                            far fa-times-circle
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <create-station ref="createStation"></create-station>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import CreateStation from './CreateStation';

export default {
    name: 'StationsList',
    components: {
        CreateStation
    },
    computed: {
        ...mapState('authentication', [
            'office_id',
            'office_login_name'
        ]),
        ...mapState('sales', [
            'date_invoice'
        ]),
        ...mapState('stations', [
            'stations',
            'selected_station'
        ])
    },
    methods: {
        ...mapActions('stations', [
            'getStations',
            'selectStation',
            'delete'
        ]),
        ...mapActions('sales', [
            'getSaleByStation',
            'setDateInvoice'
        ]),
        ...mapActions('sales', [
            'run_set_cart',            
            'clearData',
            'run_set_sale'
        ]),
        handleClearData () {
            this.run_set_cart([]);
            this.clearData();
            this.run_set_sale(null);
        },
        async handleSelectStation (station) {
            this.handleClearData();
            this.setDateInvoice(null);
            this.selectStation(station);
            if (this.selected_station.status === 'ocupada') {
                let filters = {
                    station_id: this.selected_station.id
                }
                await this.getSaleByStation(filters);
            }
            if (this.date_invoice) {
                this.setDateInvoice(this.$moment(this.date_invoice).format('YYYY-MM-DD'));
            } else {
                this.setDateInvoice(this.$moment().format('YYYY-MM-DD'));
            }
        },
        getClassStationActive(item) {
            let class_active = '';
            if (this.selected_station) {
                if (this.selected_station.id == item.id) {
                    class_active = 'station-selected';
                }
            }

            return class_active;
        },
        handleViewCreateStation () {
            this.$refs.createStation.show(this.office_id);
        },
        handleDeleteStation (station) {
            this.$swal({
                title: "¿Desea confirmar?",
                text: "Confirma que desea eliminar la estación: " + station.name ,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if(result.value){
                    if(result.value === true){
                        await this.delete(station);
                    }
                }                    
            })
        },
        load () {
            let filters = {
                office_id: this.office_id
            }
            this.getStations(filters);
        }
    },
    created () {
        this.load();
    }
}
</script>

<style scoped>
.row-station{
    border-bottom: 1px solid #d6d6d8;    
}

.station-selected{
    background-color: #e2e3e7 !important;
}

.station-item{
    cursor: pointer;
}

.station-name{
    font-size: 1.0rem !important;
    font-weight: 600;
}

.station-open{
    font-size: .85rem !important;
}

.station-close{
    font-size: .85rem !important;
    color: #F44336;
}

.open-hour{
    font-weight: 600;
    color: #2196F3;
}

.close-hour{
    font-weight: 600;
    color: #F44336;
}

.station-income{
    font-size: .85rem !important;
}

.income-amount{
    font-weight: 600;
    color: #4CAF50;
}

.libre{
    color: #4CAF50;
}

.ocupada{
    color: #F44336;
}

.label-station-status{
    display: inline-block;
    border-radius: 4px;
    width: 85px;
    text-align: center;
    font-size: .8rem;
}

.status-libre{
    border: 1px solid #4CAF50;
    color: #4CAF50;
}

.status-ocupada{
    border: 1px solid #F44336;
    color: #F44336;
}
</style>