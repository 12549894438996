<template>
    <v-dialog
        v-model="dialog"
        width="380"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-person-booth</v-icon>
                <span class="description-selected">
                    Crear nueva estación
                </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.name"
                                ref="name"
                                label="Nombre"
                                autofocus
                                outlined
                                :rules="[() => !!form.name || 'Debe ingresar nombre de estación de servicio']"
                                hide-details="auto"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleOk">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'CreateStation',
    data () {
        return {
            dialog: false,
            form: {
                office_id: null,
                name: null
            }
        }
    },
    methods: {
        ...mapActions('stations', [
            'store'
        ]),
        show (office_id) {
            this.clearData();
            this.form.office_id = office_id;
            this.dialog = !this.dialog;
        },
        async clearData () {
            this.form.office_id = null;
            this.form.name = null;
            await this.clearErrors();
        },
        handleCancel () {
            this.dialog =  false;
        },
        closeForm () {
            this.clearData();
            this.dialog = false;
        },
        async handleOk () {
            if (!this.existErrors()) {
                await this.store(this.form);
                this.closeForm();
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>